<template>
  <vue-apex-charts
    ref="lineRef"
    type="line"
    height="400"
    :options="computedOption"
    :series="computedSeries"
  />
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'

export default {
  components: { VueApexCharts },
  props: {
    options: {
      type: Object,
      required: false,
      default: () => {},
    },
    series: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props) {
    const lineRef = ref(null)
    const computedOption = computed(() => {
      if (props.options && Object.keys(props.options).length > 0) {
        return props.options
      }

      return apexChatData.lineChartSimple.chartOptions
    })

    const computedSeries = computed(() => {
      if (props.series && props.series.length > 0) {
        if (lineRef.value) lineRef.value.updateOptions(props.options)

        return props.series
      }

      return apexChatData.lineChartSimple.series
    })

    return {
      lineRef,
      apexChatData,
      computedOption,
      computedSeries,
    }
  },
}
</script>
