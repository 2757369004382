<template>
  <vue-apex-charts
    type="area"
    height="400"
    :options="computedChartOptions"
    :series="computedChartSeries"
  />
</template>

<script>
import { computed } from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    chartOptions: {
      type: Object,
      default: () => null,
    },
    chartSeries: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const computedChartOptions = computed(() => {
      if (props.chartOptions) return props.chartOptions

      return apexChatData.lineAreaChartSpline.chartOptions
    })
    const computedChartSeries = computed(() => {
      if (props.chartSeries.length) return props.chartSeries

      return apexChatData.lineAreaChartSpline.series
    })

    return {
      apexChatData,
      computedChartOptions,
      computedChartSeries,
    }
  },
}
</script>
