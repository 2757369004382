/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable arrow-body-style */
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import {
  mdiCurrencyUsd,
  mdiPound,
  mdiSoccerField,
  mdiHomeGroup,
  mdiCamera,
  mdiAccountMultiple,
  mdiViewCarouselOutline,
  mdiVideoBox,
  mdiChartBarStacked,
  mdiCellphone,
  mdiBackupRestore,
} from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import {
  getDashboardOrderSummary,
  getDashboardHighlightSummary,
  getDashboardActives,
  getDashboardUser,
  getDashboardUserFacility,
  getDashboardVideoViews,
  getDashboardFacilitySales,
  getDashboardFacilityRevenue,
  getDashboardUserRegistered,
} from '@api'
import { formatCurrency, getVuetify } from '@core/utils'
import usePermissions from '@core/utils/usePermissions'
import useCryptoJs from '@core/utils/useCryptoJs'
import useSelectOptions from '@core/utils/useSelectOptions'
import useFilters from '@core/utils/useFilters'

import themeConfig from '@themeConfig'

const $themeColors = themeConfig.themes.light

export default function useDashboard() {
  const { hasPermission } = usePermissions()
  const { updateFilter, getFilterByModule } = useFilters()
  const { t } = useUtils()
  const { userData } = useCryptoJs()
  const {
    configOrganization,
    configFacility,
  } = useSelectOptions(true)

  const customOrganization = ref(0)
  const customFacility = ref(0)
  const organizationsOptions = ref([])
  const facilitiesOptions = ref([])

  const $vuetify = getVuetify()
  const timerSearch = ref(null)

  const dateRangeOptions = computed(() => [
    { text: t('date_range.this_year'), value: 'TY' },
    { text: t('date_range.this_month'), value: 'TM' },
    { text: t('date_range.last_month'), value: 'LM' },
    { text: t('date_range.this_week'), value: 'TW' },
    { text: t('date_range.last_week'), value: 'LW' },
    { text: t('date_range.custom'), value: 'C' },
  ])

  const dateRangeFilter = ref('TM')

  const modalFrom = ref(null)
  const modalTo = ref(null)
  const minDate = ref(
    new Date('2021-01-02').toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )
  const maxDate = ref(
    new Date(+new Date() + 12096e5).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )
  const nowDate = ref(
    new Date().toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )
  const dateFrom = ref(nowDate.value)
  const dateTo = ref(nowDate.value)

  const groupSummary = ref(null)
  const activesSummary = ref(null)
  const userFacility = ref(null)
  const salesByFacility = ref(null)
  const actionsAppUser = ref(null)
  const registeredUserByFacility = ref(null)
  const uniqueViewsByFacility = ref(null)

  const activityUserSeries = ref([
    {
      data: [280, 200, 220, 180, 270, 250, 70, 90, 200, 150, 160, 100, 150, 100, 50],
    },
  ])
  const computedActivityUserSeries = computed(() => activityUserSeries.value)

  const activityUserChartOptions = ref({
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    markers: {
      strokeWidth: 7,
      strokeOpacity: 1,
      strokeColors: [$themeColors.secondary],
      colors: [$themeColors.warning],
    },
    colors: [$themeColors.warning],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    tooltip: {
      custom(data) {
        return `${'<div class="px-1 py-50"><span>'}${
          data.series[data.seriesIndex][data.dataPointIndex]
        } shares</span></div>`
      },
    },
    xaxis: {
      categories: [
        '7/12',
        '8/12',
        '9/12',
        '10/12',
        '11/12',
        '12/12',
        '13/12',
        '14/12',
        '15/12',
        '16/12',
        '17/12',
        '18/12',
        '19/12',
        '20/12',
        '21/12',
      ],
    },
    yaxis: {
      // opposite: isRtl,
    },
  })
  const computedActivityUserChartOptions = computed(() => activityUserChartOptions.value)

  // highlight created
  const highlightCreatedSeries = ref([
    {
      data: [280, 200, 220, 180, 270, 250, 70, 90, 200, 150, 160, 100, 150, 100, 50],
    },
  ])
  const computedHighlightCreatedSeries = computed(() => highlightCreatedSeries.value)

  const highlightCreatedChartOptions = ref({
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    markers: {
      strokeWidth: 7,
      strokeOpacity: 1,
      strokeColors: [$themeColors.secondary],
      colors: [$themeColors.warning],
    },
    colors: [$themeColors.warning],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    tooltip: {
      custom(data) {
        return `${'<div class="px-1 py-50"><span>'}${
          data.series[data.seriesIndex][data.dataPointIndex]
        } created</span></div>`
      },
    },
    xaxis: {
      categories: [
        '7/12',
        '8/12',
        '9/12',
        '10/12',
        '11/12',
        '12/12',
        '13/12',
        '14/12',
        '15/12',
        '16/12',
        '17/12',
        '18/12',
        '19/12',
        '20/12',
        '21/12',
      ],
    },
    yaxis: {
      // opposite: isRtl,
    },
  })
  const computedHighlightCreatedChartOptions = computed(() => highlightCreatedChartOptions.value)

  // revenue
  const revenueSeries = ref([
    {
      data: [280, 200, 220, 180, 270, 250, 70, 90, 200, 150, 160, 100, 150, 100, 50],
    },
  ])
  const computedRevenueSeries = computed(() => revenueSeries.value)

  const revenueChartOptions = ref({
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    markers: {
      strokeWidth: 7,
      strokeOpacity: 1,
      strokeColors: [$themeColors.secondary],
      colors: [$themeColors.warning],
    },
    colors: [$themeColors.warning],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    tooltip: {
      custom(data) {
        return `${'<div class="px-1 py-50"><span>'}${
          data.series[data.seriesIndex][data.dataPointIndex]
        } $</span></div>`
      },
    },
    xaxis: {
      categories: [
        '7/12',
        '8/12',
        '9/12',
        '10/12',
        '11/12',
        '12/12',
        '13/12',
        '14/12',
        '15/12',
        '16/12',
        '17/12',
        '18/12',
        '19/12',
        '20/12',
        '21/12',
      ],
    },
    yaxis: {
      // opposite: isRtl,
    },
  })
  const computedRevenueChartOptions = computed(() => revenueChartOptions.value)

  const customChartColor = '#f5f5f5'
  const chartColors = [
    customChartColor,
    customChartColor,
    customChartColor,
    customChartColor,
    $vuetify.theme.currentTheme.success,
    customChartColor,
    customChartColor,
  ]

  // ------------------------------------------------
  const chartCategories = ref(['S', 'M', 'T', 'W', 'T', 'F', 'S'])
  const computedChartCategories = computed({
    get: () => chartCategories.value,
    set: val => { chartCategories.value = val },
  })

  const chartX = ref([1, 2, 3, 4, 5, 6, 7])
  const computedChartX = computed({
    get: () => chartX.value,
    set: val => { chartX.value = val },
  })

  const computedAuxChartData = computed(() => {
    return [
      {
        name: t('statistics.order_number'),
        data: computedChartCategories.value.map((e, i) => {
          return {
            x: e, y: computedChartX.value[i], fillColor: $vuetify.theme.currentTheme.secondary,
          }
        }),
      },
    ]
  })

  // -------------------------------------------------------
  // ---------------------Area-------------------------

  const areaSeries = ref([
    {
      name: 'Visits',
      data: [100, 120, 90, 170, 130, 160, 140, 240, 220, 180, 270, 280, 375],
    },
  ])
  const computedAreaSeries = computed(() => areaSeries.value)
  const areaChartOptions = ref({
    chart: {
      toolbar: {
        show: true,
        offsetY: -15,
        tools: {
          download: false,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
      },
      offsetX: -15,
      zoom: {
        enabled: true,
        type: 'x',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
      curve: 'straight',
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'left',
      fontSize: '14px',
      fontFamily: '"Inter", sans-serif',
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      categories: [
        '7/12',
        '8/12',
        '9/12',
        '10/12',
        '11/12',
        '12/12',
        '13/12',
        '14/12',
        '15/12',
        '16/12',
        '17/12',
        '18/12',
        '19/12',
        '20/12',
      ],
    },
    yaxis: {
      // opposite: isRtl
    },
    fill: {
      opacity: 1,
      type: 'solid',
    },
    tooltip: {
      shared: false,
    },
    colors: ['#FF3232'],
  })
  const computedAreaChartOptions = computed(() => areaChartOptions.value)

  // -------------------------------------------------------

  // chart card orders number
  const totalOrdersNumberOptions = ref({
    statTitle: t('statistics.order_number'),
    tooltip: 'tooltip',
    icon: mdiPound,
    color: 'info',
    subtitle: t('statistics.this_month'),
    statistics: '0',
    change: '+6%',
    showBtnDots: false,
    showChange: false,
  })
  const computedTotalOrdersNumberOptions = computed(() => totalOrdersNumberOptions.value)

  // chart card orders
  const totalOrdersOptions = ref({
    statTitle: t('statistics.orders'),
    tooltip: 'tooltip',
    icon: mdiCurrencyUsd,
    color: 'success',
    subtitle: t('statistics.this_month'),
    statistics: '0',
    change: '+6%',
    showBtnDots: false,
    showChange: false,
  })
  const computedTotalOrdersOptions = computed(() => totalOrdersOptions.value)

  // chart card orders
  const totalComissionsOptions = ref({
    statTitle: t('statistics.comissions'),
    tooltip: 'tooltip',
    icon: mdiCurrencyUsd,
    color: 'success',
    subtitle: t('statistics.this_month'),
    statistics: '0',
    change: '+42%',
    showBtnDots: false,
    showChange: false,
  })
  const computedTotalComissionsOptions = computed(() => totalComissionsOptions.value)

  // chart refunds amount
  const totalRefundsOptions = ref({
    statTitle: t('statistics.refunds_amount'),
    tooltip: 'tooltip',
    icon: mdiBackupRestore,
    color: 'success',
    subtitle: t('statistics.this_month'),
    statistics: '0',
    change: '+42%',
    showBtnDots: false,
    showChange: false,
  })
  const computedTotalRefundsOptions = computed(() => totalRefundsOptions.value)

  // chart facility numbers
  const totalFacilitiesOptions = ref({
    statTitle: t('statistics.actives_facility'),
    tooltip: 'tooltip',
    icon: mdiHomeGroup,
    color: 'info',
    subtitle: t('statistics.this_month'),
    statistics: '0',
    change: '+42%',
    showBtnDots: false,
    showChange: false,
  })
  const computedTotalFacilitiesOptions = computed(() => totalFacilitiesOptions.value)

  // chart fields numbers
  const totalFieldsOptions = ref({
    statTitle: t('statistics.actives_field'),
    tooltip: 'tooltip',
    icon: mdiSoccerField,
    color: 'info',
    subtitle: t('statistics.this_month'),
    statistics: '0',
    change: '+42%',
    showBtnDots: false,
    showChange: false,
  })
  const computedTotalFieldsOptions = computed(() => totalFieldsOptions.value)

  // chart cameras numbers
  const totalCamerasOptions = ref({
    statTitle: t('statistics.actives_camera'),
    tooltip: 'tooltip',
    icon: mdiCamera,
    color: 'info',
    subtitle: t('statistics.this_month'),
    statistics: '0',
    change: '+42%',
    showBtnDots: false,
    showChange: false,
  })
  const computedTotalCamerasOptions = computed(() => totalCamerasOptions.value)

  // chart user numbers
  const totalUsersOptions = ref({
    statTitle: t('statistics.actives_camera'),
    tooltip: 'tooltip',
    icon: mdiAccountMultiple,
    color: 'info',
    subtitle: t('statistics.this_month'),
    statistics: '0',
    change: '+42%',
    showBtnDots: true,
    showChange: false,
  })
  const computedTotalUsersOptions = computed(() => totalUsersOptions.value)

  // chart App Sessions
  const totalAppSessionsOptions = ref({
    statTitle: t('statistics.actives_camera'),
    tooltip: 'tooltip',
    icon: mdiCellphone,
    color: 'info',
    subtitle: t('statistics.this_month'),
    statistics: '0',
    change: '+42%',
    showBtnDots: true,
    showChange: false,
  })
  const computedTotalAppSessionsOptions = computed(() => totalAppSessionsOptions.value)

  // chart number videos
  const totalNumberVideosOptions = ref({
    statTitle: t('statistics.actives_camera'),
    tooltip: 'tooltip',
    icon: mdiVideoBox,
    color: 'error',
    subtitle: t('statistics.this_month'),
    statistics: '0',
    change: '+42%',
    showBtnDots: false,
    showChange: false,
  })
  const computedTotalNumberVideosOptions = computed(() => totalNumberVideosOptions.value)

  // chart Unique views
  const totalUniqueViewsOptions = ref({
    statTitle: t('statistics.actives_camera'),
    tooltip: 'tooltip',
    icon: mdiViewCarouselOutline,
    color: 'error',
    subtitle: t('statistics.this_month'),
    statistics: '$0',
    change: '+42%',
    showBtnDots: true,
    showChange: false,
  })
  const computedTotalUniqueViewsOptions = computed(() => totalUniqueViewsOptions.value)

  // chart Avg per video
  const totalAvgVideoOptions = ref({
    statTitle: t('statistics.actives_camera'),
    tooltip: 'tooltip',
    icon: mdiChartBarStacked,
    color: 'error',
    subtitle: t('statistics.this_month'),
    statistics: '0',
    change: '+42%',
    showBtnDots: true,
    showChange: false,
  })
  const computedTotalAvgVideoOptions = computed(() => totalAvgVideoOptions.value)

  // chart registered user
  const totalRegisteredUser = ref({
    statTitle: t('statistics.registered_app_users'),
    tooltip: 'tooltip',
    icon: mdiCellphone,
    color: 'info',
    subtitle: t('statistics.this_month'),
    statistics: '0',
    change: '+42%',
    showBtnDots: false,
    showChange: false,
  })
  const computedTotalRegisteredUser = computed(() => totalRegisteredUser.value)

  const fetchOrderSummary = async (group, facility, range, start, end) => {
    let response = null
    if (group) {
      response = await getDashboardOrderSummary(group, facility, range, start, end)
    } else if (userData.value && userData.value.role !== 'A') {
      response = await getDashboardOrderSummary(userData.value.group_id, facility, range, start, end)
    } else {
      response = await getDashboardOrderSummary(0, 0, range, start, end)
    }

    if (response.ok) {
      groupSummary.value = response.data

      computedChartCategories.value = groupSummary.value.categories
      computedChartX.value = groupSummary.value.data

      totalOrdersOptions.value.statistics = formatCurrency(groupSummary.value.orders_amount)
      totalOrdersOptions.value.change = groupSummary.value.orders_diff

      totalComissionsOptions.value.statistics = formatCurrency(groupSummary.value.commissions_amount)
      totalComissionsOptions.value.change = groupSummary.value.commissions_diff

      totalRefundsOptions.value.statistics = formatCurrency(groupSummary.value.refunds_amount)
      totalRefundsOptions.value.change = groupSummary.value.commissions_diff

      totalOrdersNumberOptions.value.statistics = groupSummary.value.orders_qty
      totalOrdersNumberOptions.value.change = groupSummary.value.orders_qty
    }
  }

  const fetchActivesSummary = async (group, facility) => {
    let response = null
    if (group) {
      response = await getDashboardActives(group, facility)
    } else if (userData.value && userData.value.role !== 'A') {
      response = await getDashboardActives(userData.value.group_id, facility)
    } else {
      response = await getDashboardActives(0, facility)
    }

    if (response.ok) {
      activesSummary.value = response.data
      totalFacilitiesOptions.value.statistics = activesSummary.value.facilities
      totalFacilitiesOptions.value.change = '+42%'

      totalFieldsOptions.value.statistics = activesSummary.value.fields
      totalFieldsOptions.value.change = '+42%'

      totalCamerasOptions.value.statistics = activesSummary.value.cameras
      totalCamerasOptions.value.change = '+42%'
    }
  }

  const fetchUserSummary = async (group, facility, range, start, end) => {
    let response = null
    response = await getDashboardUser(group, facility, range, start, end)

    if (response.ok) {
      const userSummary = response.data
      totalUsersOptions.value.statistics = userSummary.active_app_users.active_app_users_qty
      totalUsersOptions.value.change = userSummary.active_app_users.diff_percent

      totalAppSessionsOptions.value.statistics = userSummary.active_app_sessions.active_app_sessions_qty
      totalAppSessionsOptions.value.change = userSummary.active_app_sessions.diff_percent

      if (userSummary.active_app_users.list_active_app_user.length) {
        actionsAppUser.value = userSummary.active_app_users.list_active_app_user.map(e => {
          return {
            ...e,
            facility_logo: e.user_avatar,
            facility_name: e.username,
            facility_data: e.qty,
          }
        })
      } else actionsAppUser.value = []
    }
  }

  const fetchUserFacility = async (group, facility, range, start, end) => {
    let response = null
    response = await getDashboardUserFacility(group, facility, range, start, end)

    if (response.ok) userFacility.value = response.data
    else userFacility.value = []
  }

  const fetchSalesFacility = async (group, facility, range, start, end) => {
    let response = null
    response = await getDashboardFacilitySales(group, facility, range, start, end)
    if (response.ok) salesByFacility.value = response.data
    else salesByFacility.value = []
  }

  const fetchUserRegistered = async (group, facility, range, start, end) => {
    let response = null
    response = await getDashboardUserRegistered(group, facility, range, start, end)
    if (response.ok) {
      registeredUserByFacility.value = response.data.reg_users_facility
      totalRegisteredUser.value.statistics = response.data.reg_users_total
      totalRegisteredUser.value.change = 0
    } else {
      registeredUserByFacility.value = []
    }
  }

  const fetchVideoViews = async (group, facility, range, start, end) => {
    const response = await getDashboardVideoViews(group, facility, range, start, end)
    const videoViews = response.data

    if (response.ok) {
      totalNumberVideosOptions.value.statistics = videoViews.active_videos
      totalNumberVideosOptions.value.change = 0

      totalUniqueViewsOptions.value.statistics = videoViews.unique_views
      totalUniqueViewsOptions.value.change = 0

      totalAvgVideoOptions.value.statistics = videoViews.avg_per_video
      totalAvgVideoOptions.value.change = 0

      uniqueViewsByFacility.value = videoViews.unique_views_facilities
    } else {
      uniqueViewsByFacility.value = []
    }
  }

  const fetchHighlightSummary = async (group, facility, range, start, end) => {
    let response = null
    if (group) {
      response = await getDashboardHighlightSummary(group, facility, range, start, end)
    } else if (userData.value && userData.value.role !== 'A') {
      response = await getDashboardHighlightSummary(userData.value.group_id, facility, range, start, end)
    } else {
      response = await getDashboardHighlightSummary(0, 0, range, start, end)
    }

    if (response && response.ok) {
      activityUserSeries.value = [
        {
          data: response.data.data,
        },
      ]
      activityUserChartOptions.value.xaxis.categories = response.data.categories

      highlightCreatedSeries.value = [
        {
          data: response.data.data_highlight_created,
        },
      ]
      highlightCreatedChartOptions.value.xaxis.categories = response.data.categories
    }
  }

  const fetchFacilityRevenue = async (group, facility, range, start, end) => {
    let response = null
    if (group) {
      response = await getDashboardFacilityRevenue(group, facility, range, start, end)
    } else if (userData.value && userData.value.role !== 'A') {
      response = await getDashboardFacilityRevenue(userData.value.group_id, facility, range, start, end)
    } else {
      response = await getDashboardFacilityRevenue(0, 0, range, start, end)
    }
    if (response && response.ok) {
      revenueSeries.value = [
        {
          data: response.data.data,
        },
      ]
      revenueChartOptions.value.xaxis.categories = response.data.categories
    }
  }

  const fetchSummary = async () => {
    let gruop = 0
    if (userData.value && userData.value.role !== 'A') gruop = userData.value.group_id
    else if (userData.value && userData.value.role === 'A' && customOrganization.value) gruop = customOrganization.value
    else gruop = 0

    await fetchOrderSummary(
      gruop,
      customFacility.value,
      dateRangeFilter.value,
      dateFrom.value || nowDate.value,
      dateTo.value || nowDate.value,
    )

    await fetchHighlightSummary(
      gruop,
      customFacility.value,
      dateRangeFilter.value,
      dateFrom.value || nowDate.value,
      dateTo.value || nowDate.value,
    )

    await fetchVideoViews(
      gruop,
      customFacility.value,
      dateRangeFilter.value,
      dateFrom.value || nowDate.value,
      dateTo.value || nowDate.value,
    )

    await fetchSalesFacility(
      gruop,
      customFacility.value,
      dateRangeFilter.value,
      dateFrom.value || nowDate.value,
      dateTo.value || nowDate.value,
    )

    await fetchUserRegistered(
      gruop,
      customFacility.value,
      dateRangeFilter.value,
      dateFrom.value || nowDate.value,
      dateTo.value || nowDate.value,
    )

    await fetchActivesSummary(
      gruop,
      customFacility.value,
    )

    await fetchUserSummary(
      gruop,
      customFacility.value,
      dateRangeFilter.value,
      dateFrom.value || nowDate.value,
      dateTo.value || nowDate.value,
    )

    await fetchUserFacility(
      gruop,
      customFacility.value,
      dateRangeFilter.value,
      dateFrom.value || nowDate.value,
      dateTo.value || nowDate.value,
    )

    await fetchFacilityRevenue(
      gruop,
      customFacility.value,
      dateRangeFilter.value,
      dateFrom.value || nowDate.value,
      dateTo.value || nowDate.value,
    )
  }

  const fetchFacilitiesByOrganization = async organizationId => {
    customFacility.value = 0
    let resp = null
    let facilities = []
    const all = [{
      id: 0,
      name: 'All Facilities',
    }]

    if (organizationId) {
      resp = null
    } else if (customOrganization.value) {
      resp = null
    }

    if (resp && resp.ok) {
      facilities = resp.data
      if (facilities.length === 1) {
        facilitiesOptions.value = facilities
        customFacility.value = facilities.at(0).id
      } else if (facilities.length > 1) {
        facilitiesOptions.value = all.concat(facilities)
        customFacility.value = 0
      }
    } else {
      facilitiesOptions.value = all
    }
  }

  const timerFetch = ref(null)
  const debounceFetch = async (time = 1000) => {
    clearTimeout(timerFetch.value)
    timerFetch.value = setTimeout(async () => {
      await fetchFacilitiesByOrganization(customOrganization.value)
    }, time)
  }

  const fetchOrganizations = async () => {
    const all = [{
      id: 0,
      name: 'All Organizations',
      logo: null,
      facilities: [],
    }]
    const resp = null
    if (resp.ok) {
      organizationsOptions.value = all.concat(resp.data)
      customOrganization.value = 0
    } else organizationsOptions.value = all
    customFacility.value = 0
  }

  watch([customFacility, dateRangeFilter, dateFrom, dateTo], async () => {
    if (customFacility.value === null) customFacility.value = 0
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      await fetchSummary()
    }, 500)
  })

  watch([customOrganization], async () => {
    if (customOrganization.value === null) customOrganization.value = 0
    clearTimeout(timerSearch.value)
    if (userData.value.role === 'A') await debounceFetch(200)
    timerSearch.value = setTimeout(async () => {
      await fetchSummary()
    }, 500)
  })

  watch([configOrganization], async () => {
    if (userData.value.role !== 'A') customOrganization.value = configOrganization.value
  })

  watch([configFacility], async () => {
    dateRangeFilter.value = 'TM'
    dateFrom.value = null
    dateTo.value = null
    if (userData.value.role !== 'A') customFacility.value = configFacility.value
  })

  onMounted(async () => {
    if (userData.value.role !== 'A') {
      customOrganization.value = configOrganization.value
      customFacility.value = configFacility.value
    } else {
      await fetchOrganizations()
      await debounceFetch(500)
    }

    await fetchSummary()
  })

  return {
    userData,

    dateRangeOptions,
    dateRangeFilter,
    modalFrom,
    modalTo,
    dateFrom,
    dateTo,
    minDate,
    maxDate,
    nowDate,

    computedTotalOrdersNumberOptions,
    computedTotalOrdersOptions,
    computedTotalComissionsOptions,
    computedTotalRefundsOptions,
    computedChartCategories,
    chartColors,
    computedAuxChartData,

    computedActivityUserSeries,
    computedActivityUserChartOptions,
    computedHighlightCreatedSeries,
    computedHighlightCreatedChartOptions,
    computedRevenueSeries,
    computedRevenueChartOptions,

    computedAreaSeries,
    computedAreaChartOptions,

    computedTotalFacilitiesOptions,
    computedTotalFieldsOptions,
    computedTotalCamerasOptions,
    computedTotalUsersOptions,
    computedTotalAppSessionsOptions,
    computedTotalNumberVideosOptions,
    computedTotalUniqueViewsOptions,
    computedTotalAvgVideoOptions,
    computedTotalRegisteredUser,

    userFacility,
    salesByFacility,
    actionsAppUser,
    registeredUserByFacility,
    uniqueViewsByFacility,

    organizationsOptions,
    facilitiesOptions,
    customOrganization,
    customFacility,
    debounceFetch,
    fetchOrganizations,

    // i18n
    t,

    fetchSummary,
    fetchOrderSummary,
    fetchHighlightSummary,
    fetchFacilityRevenue,
    fetchVideoViews,
    fetchActivesSummary,
    fetchUserFacility,
    fetchUserSummary,
    fetchSalesFacility,
    fetchUserRegistered,
    hasPermission,
    updateFilter,
    getFilterByModule,
  }
}
